export function authHeader() {
  return {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "Application/json"
  };
}

export function authHeaderLang(lang) {
  return {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "Application/json",
    "lang": lang
  };
}