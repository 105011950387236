import { createStore } from 'vuex'


export default createStore({
  modules: {
  },
  state: {
  },
  mutations: {
  },
  actions: {
  }
})
