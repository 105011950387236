import axios from 'axios';
import { authHeader, authHeaderLang } from './auth-header';

import { URL_API } from './Commons';

class ProductService {

    async getAll(lang) {
        return await axios.get(URL_API + 'product', { headers: authHeaderLang(lang) });
    }

    async getAllHome(lang) {
        return await axios.get(URL_API + 'product/home', { headers: authHeaderLang(lang) });
    }

    async getAllMenu(lang) {
        return await axios.get(URL_API + 'product/menu', { headers: authHeaderLang(lang) });
    }

    async getOne(id) {
        return axios.get(URL_API + 'product/' + id, { headers: authHeader() });
    }

    async getOneByTitle(title, lang) {
        return axios.get(URL_API + 'product/title/' + title, { headers: authHeaderLang(lang) });
    }


}

export default new ProductService();