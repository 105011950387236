import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'

import en from './translations/en.json'
import es from './translations/es.json'
import pt from './translations/br.json'

const i18n = createI18n({
    locale: 'es',
    fallbackLocale: 'es',
    messages: {
        en: en,
        es: es,
        pt: pt
    },
    useScope: 'global'
})

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '@fortawesome/fontawesome-free'
import './assets/css/all.min.css'
import './assets/css/style.css'

import VueGtag from "vue-gtag";

let app = createApp(App)

if (!localStorage.locale) {
    localStorage.locale = "es"
}

app.config.globalProperties.$changei18n = (locale) => {
    i18n.global.locale = locale;
}

if (localStorage.locale) {
    i18n.global.locale = localStorage.locale;
}

app.use(store).use(router).use(i18n).use(VueGtag, {
    config: { id: "G-N132BHHQWN" }
    //config: { id: "G-CX3BT85M65" }
}).mount('#app');