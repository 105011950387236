import axios from 'axios';
import {authHeader} from './auth-header';

import { URL_API } from './Commons';

class AboutService {

  async getAll() {
    return await axios.get(URL_API + 'about', { headers: authHeader() });
  }

  getOne() {
    return axios.get(URL_API + 'about', { headers: authHeader() });
  }


}

export default new AboutService();