<template>
  <div id="nav">
    <top-menu></top-menu>
  </div>
  <router-view/>
  <FooterMenu/>
  <HeaderSeoComponent/>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
import FooterMenu from '@/components/FooterMenu.vue'
import HeaderSeoComponent from "@/components/HeaderSeoComponent.vue";
export default {
  name: 'App',
  components: {
    TopMenu, FooterMenu, HeaderSeoComponent
  }
}
</script>

<style lang="stylus">

</style>
