<template>
  <div class="footer">
    <section class="curve-botton">
      <div class="container">
        <div class="row">
          <div class="link-top">
            <a href="#top">
              <div class="mx-auto">
                <i class="fas fa-chevron-up bounce"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 150">
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Footer">
            <path
              class="cls-1"
              d="M0,30.61S138-47.85,583.12,66.68C850,135.35,1141.49,121.91,1357,57.22c350-105.07,564-33.29,564-33.29l-1,893.36H1Z"
            />
          </g>
        </g>
      </svg>
    </section>
    <section>
      <footer>
        <div class="container" style="margin-top: -1px">
          <div class="row">
            <div class="col-sm-12 col-md-2 mt-5 mb-3">
              <img
                src="../assets/images/logoB.png"
                class="img-fluid mb-4"
                alt=""
              />
              <h5><b>Paraguay</b></h5>
              <small>
                Ciudad del Este | Asunción<br />
                +595 983 670 260 </small
              ><br />
              <!-- <img src="./images/gptw.png" alt="" class="img-fluid mt-4"> -->
            </div>
            <div class="col-sm-12 col-md-1"></div>
            <div class="col-sm-12 col-md-6 mt-5 mb-3 imgdesktop">
              <div class="row menufooter imgdesktop">
                <div class="col">
                  <h5>{{ $t("products") }}</h5>
                  <ul class="nav flex-column">
                    <li class="nav-item mb-1" v-for="productF in productListF" :key="productF.id">
                      <a
                        class="nav-link p-0"
                        :href="'/product/' + productF.title"
                        >{{ productF.title }}</a
                      >
                    </li>
                    <li class="nav-item mb-1">
                      <a class="nav-link p-0" href="https://facturae.com.py" target="_blank"
                        >Facturación Electrónica</a
                      >
                    </li>
                    <!-- <li class="nav-item mb-1">
                      <a class="nav-link p-0" href="/complementares"
                        >Apps Dolphin</a
                      >
                    </li> -->
                    <!-- <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">Dolphin ERP</a>
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">Vistra BI</a>
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">Clover CRM</a>
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">Backup Datos</a>
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">Backup Cloud</a>
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0"
                        >Consultoría de software</a
                      >
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">Consultoría de infra</a>
                    </li> -->
                  </ul>
                </div>

                <div class="col">
                  <h5>{{ $t("about") }}</h5>
                  <ul class="nav flex-column">
                    <li class="nav-item mb-1">
                      <a href="/about/#history" class="nav-link p-0">{{$t("history")}}</a>
                    </li>
                    <li class="nav-item mb-1">
                      <a href="/about/#organizationalCulture" class="nav-link p-0"
                        >{{$t("orgCulture")}}</a
                      >
                    </li>
                    <!-- <li class="nav-item mb-1">
                      <a href="/about/#socialResponsability" class="nav-link p-0"
                        >Responsabilidad social</a
                      >
                    </li> -->
                  </ul>

                  <h5>{{ $t("cases") }}</h5>
                  <ul class="nav flex-column">
                    <li class="nav-item mb-1">
                      <a href="/cases/#successCases" class="nav-link p-0">{{$t("customers")}}</a>
                    </li>
                    <!-- <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">Testimonios</a>
                    </li> -->
                  </ul>

                  <!-- <h5>Área del cliente</h5>
                  <ul class="nav flex-column">
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">Soporte</a>
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">Consultoria</a>
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0"
                        >Actualizaciones de software</a
                      >
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">Reclamos</a>
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0"
                        >Mejorías y sugerencias</a
                      >
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">Base de conocimiento</a>
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">Descarga Teamviewer</a>
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">Programa Partner</a>
                    </li>
                  </ul> -->
                </div>

                <div class="col">
                  <!-- <h5>Datapar Academy</h5>
                  <ul class="nav flex-column">
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0"
                        >Cursos y certificaciones</a
                      >
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">Cursos gratis</a>
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">Pricing</a>
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">FAQs</a>
                    </li>
                    <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0">About</a>
                    </li>
                  </ul> -->

                  <h5>{{ $t("workwithus") }}</h5>
                  <ul class="nav flex-column">
                    <!-- <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0"
                        >Oportunidades de vacancia</a
                      >
                    </li> -->
                    <li class="nav-item mb-1">
                      <a href="/work/#AreGPTW" class="nav-link p-0">{{$t("cultureGPTW")}}</a>
                    </li>
                    <!-- <li class="nav-item mb-1">
                      <a href="#" class="nav-link p-0"
                        >Base de datos de talentos</a
                      >
                    </li> -->                    
                  </ul>

                  <!-- <h5>Contenidos</h5>
                  <ul class="nav flex-column">
                    <li class="nav-item mb-2">
                      <a href="#" class="nav-link p-0">Ebooks</a>
                    </li>
                    <li class="nav-item mb-2">
                      <a href="#" class="nav-link p-0">Blog</a>
                    </li>
                  </ul> -->

                  <h5>Datapar Business | Partner Program</h5>
                  <ul class="nav flex-column">
                    <li class="nav-item mb-1">
                      <a href="/partners" class="nav-link p-0">Encuentre a nuestros partners</a>
                    </li>                   
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-1"></div>
            <div class="col-sm-12 col-md-2 mt-5 mb-3 text-center">
              {{ $t("doubts") }}
              <!-- <div class="d-grid gap-2 mt-3">
                <a href="#" class="btn btn-blanco">{{ $t("talk") }}</a>
              </div> -->
              <div class="d-grid gap-2 mt-3">
                <a
                  href="https://dataparassist.freshdesk.com/support/home"
                  target="_blank"
                  class="btn btn-blanco btn-block"
                  >{{ $t("support") }}</a
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="container">
              <div class="row col-sm-12 col-md-3 col-lg-3 mx-auto">
                <div class="col-3 col-sm-3 col-md-3 menufooter-social text-center">
                  <a :href="aboutItem.urlFacebook" target="_blank">
                    <div class="mx-auto"><i class="fab fa-facebook-f"></i></div>
                  </a>
                </div>
                <div class="col-3 col-sm-3 col-md-3 col-lg-3 menufooter-social text-center">
                  <a :href="aboutItem.urlInstagram" target="_blank">
                    <div class="mx-auto"><i class="fab fa-instagram"></i></div>
                  </a>
                </div>
                <div class="col-3 col-sm-3 col-md-3 col-lg-3 menufooter-social text-center">
                  <a :href="aboutItem.urlLinkedin" target="_blank">
                    <div class="mx-auto"><i class="fab fa-linkedin-in"></i></div>
                  </a>
                </div>
                <div class="col-3 col-sm-3 col-md-3 col-lg-3 menufooter-social text-center">
                  <a :href="aboutItem.urlYoutube" target="_blank">
                    <div class="mx-auto"><i class="fab fa-youtube"></i></div>
                  </a>
                </div>
              </div>              
            </div>

            <hr />
          </div>
          <div class="row">
            <div class="col-12 mb-4 text-center menufooter-link small">
              <b>Datapar S.A {{ new Date().getFullYear() }}</b> /
              {{ $t("developer") }}
              <i class="bi bi-heart-fill text-white" title="Corazón"></i>
              {{ $t("devFor") }}
              <a href="https://vrclic.com.br" target="_blank"
                >VRCLIC - Soluções Digitais <i class="far fa-registered"></i
              ></a>
              / <i class="far fa-copyright"></i> {{ $t("rights") }}
            </div>
          </div>
        </div>
      </footer>
    </section>
  </div>
</template>

<script>
import AboutService from "../services/about.service";
import ProductService from "../services/product.service";
export default {
  name: "Footer",
  data() {
    return {
      aboutItem: [],
      productListF: [],      
    };
  },
  mounted() {    
    AboutService.getOne().then((result) => {
      if (result.status < 400) {
        this.aboutItem = result.data;
      }
    });

    ProductService.getAllHome(localStorage.locale).then((result) => {
      if (result.status < 400) {
        this.productListF = result.data;
      }
    });

    // let rdWhatsapp = document.createElement('script');
    // rdWhatsapp.setAttribute('src', 'https://d335luupugsy2.cloudfront.net/js/loader-scripts/e3040e12-c4db-40e6-8b62-e1aed05d4ec0-loader.js');
    // document.head.appendChild(rdWhatsapp);

    // let clientifyChatBot = document.createElement('script');
    // clientifyChatBot.setAttribute('src', 'https://api.clientify.net/web-marketing/chatbots/script/165421.js');
    // document.head.appendChild(clientifyChatBot);
  },
  
};
</script>
<style>
.cls-1 {
  fill: #d12e29;
}
</style>