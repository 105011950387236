import axios from 'axios';
import { authHeaderLang } from './auth-header';

import { URL_API } from './Commons';

class HeaderSeoService {

    async getAll(lang) {
        return await axios.get(URL_API + 'header', { headers: authHeaderLang(lang) });
    }

    async getAllProduto(title, lang) {
        return await axios.get(URL_API + 'header' + title, { headers: authHeaderLang(lang) });
    }
}

export default new HeaderSeoService();