import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ('../views/home/Home.vue'),
    },
    {
        path: '/home',
        name: 'Home1',
        component: () =>
            import ('../views/home/Home.vue'),
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import ('../views/about/About.vue'),
    },
    {
        path: '/customer',
        name: 'Customer',
        component: () =>
            import ('../views/customer/Customer.vue'),
    },
    {
        path: '/productalias/:idproduct',
        name: 'ProductId',
        component: () =>
            import ('../views/product/Product.vue'),
    },
    {
        path: '/product/:titleProd',
        name: 'Product',
        component: () =>
            import ('../views/product/Product.vue'),
    },
    {
        path: '/complementares',
        name: 'Complementares',
        component: () =>
            import ('../views/product/comp/Complementares.vue'),
    },
    {
        path: '/segment',
        name: 'Segment',
        component: () =>
            import ('../views/segment/Segment.vue'),
    },
    {
        path: '/cases',
        name: 'Cases',
        component: () =>
            import ('../views/cases/Cases.vue'),
    },
    {
        path: '/work',
        name: 'Work',
        component: () =>
            import ('../views/work/Work.vue'),
    },
    {
        path: '/search/:word',
        name: 'Search',
        component: () =>
            import ('../views/search/Search.vue'),
    },
    {
        path: '/partners',
        name: 'Partners',
        component: () =>
            import ('../views/partners/Partners.vue'),
    }
]



const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: 'datapar-active-link',
})

// router.beforeEach((to, from, next) => {

//   const publicPages = ['/login', '/home', '/'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');
//   if (authRequired && !loggedIn) {
//       next('/login');
//   } else {
//       next();
//   }
// });

export default router