<template>
  <section>
    <teleport to="head">
      <title>{{ title }} - La Mayor Plataforma de Software para el Agro</title>
      <meta name="description" :content="description" />
      <meta name="keywords" :content="keyWords" />

      <link rel="icon" :href="faviconPath" :key="keyfavicon" />

      <meta property="og:url" :content="urlSite" />
      <meta property="og:title" :content="title" />
      <meta property="og:image" :content="imgPath" :key="keyimg" />
      <meta property="og:description" :content="description" />

      <meta property="article:author" content="" />

      <meta name="twitter:site" content="@" />
      <meta name="twitter:title" :content="title" />
      <meta name="twitter:creator" content="@" />
      <meta name="twitter:description" :content="description" />
    </teleport>
  </section>
</template>

<script>
import { useRoute } from "vue-router";
import { computed } from "vue";
import headerSeoService from "../services/headerseo.service.js";
import { URL_BASE } from "../services/Commons";
export default {
  name: "HeaderSeoComponent",
  data() {
    return {
      title: "",
      description: "",
      keyWords: "",
      imgPath: "",
      faviconPath: "datapar_icon.png",
      urlSite: "",
      keyfavicon:10,
      keyimg: 10
    };
  },
  mounted() {
    const route = useRoute();
    setTimeout(() => {
      const path = computed(() => {
        return route.path;
      });
      console.log(path.value);
      
      if (path.value.includes("product")) {
        
        headerSeoService
          .getAllProduto(path.value, localStorage.locale)
          .then((result) => {            
            if (result.status < 400) {              
              this.title = result.data.title;
              this.keyWords = result.data.keywords;
              this.description = result.data.description;
              this.imgPath = URL_BASE+"file/product/byField/"+result.data.id+"/icoPath";
              this.faviconPath = URL_BASE+"file/product/byField/"+result.data.id+"/faviconPath";
              this.keyfavicon += 1;
              this.keyimg += 1;
            }
          });
      } else {        
        headerSeoService.getAll(localStorage.locale).then((result) => {          
          if (result.status < 400) {
            this.title = result.data.title;
            this.keyWords = result.data.keywords;
            this.description = result.data.description;
            this.keyfavicon += 1;
            this.keyimg += 1;
          }
        });
      }
      this.urlSite = URL_BASE+path.value;
    }, 300);
  },
};
</script>

<style>
</style>